<template>

<form class="d-flex" role="search" @submit.prevent="submitsearch" >
        <input class="form-control me-2" v-model="search" type="search" placeholder="Search" aria-label="Search" @keyup.enter="submitsearchenter">

        <div class="btn-group dropstart">
          <button id="btnsearch" type="button" class="btn btn-outline-light dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" @click.prevent="submitsearch">
            Search
          </button>
          <ul id="myDropdown" class="dropdown-menu">
            <template v-for="albumfiltre in albumsfiltres" :key="albumfiltre">
              <li>
                <a :href="'/albumdetail/'+albumfiltre.id" class="dropdown-item">
                  {{ albumfiltre.titre }}
                </a>
              </li>
            </template>
              <li v-if="!albumsfiltres.length" class="text-center">
                Aucun résultat
              </li>
          </ul>
        </div>

  </form>
</template>

<style>
.resultatsearch {
  z-index: 1;
}
</style>
<script>

// Sets an item with a Key to local storage
const saveStorage = function(key, data) {
    localStorage.setItem(key, JSON.stringify(data));
};

// Looks for a local storage item and returns if present
const getStorage = function(key,item) {
    if( localStorage.getItem(key) && item) {
        const data = JSON.parse(localStorage.getItem(key))
        return data[item]
    }
    else if(localStorage.getItem(key)) {
       return JSON.parse(localStorage.getItem(key))
    }
};

export default {
  name: "SearchBar",
  data() {
    return {
      search: getStorage('search'),
      albumsfiltres: []
    }
  },
  methods: {
    submitsearchenter() {
      console.log('submitsearchenter')
      var eventFocus = new Event('click');
      document.querySelector('#btnsearch').dispatchEvent(eventFocus);
    },
    submitsearch() {
      console.log(this.search)
      // this.$emit('submit', this.search)


      saveStorage('search',this.search);

      if (this.search!="")
      {
        const headers = { "Accept": "application/json" };
        fetch('https://marvel.ksp.al/api/albums/0/filtre/'+this.search, { headers })
          .then(response => response.json())
          .then(data => {
            this.albumsfiltres = data
          })
      }
    }
  }
}
</script>