import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
        path: '/',
        name: 'Home',
        component: () => import('../views/HomePage')
    },
    {
        path: '/albumliste',
        name: 'AlbumListe',
        component: () => import('../views/AlbumListePage')
    },
    {
        path: '/albumdetail/:id',
        name: 'AlbumDetail',
        component: () => import('../views/AlbumDetailPage'),
        props: true
    },
    {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: () => import('../views/NotFound')
    }
  ]
})

export default router