<template>

  <nav class="navbar navbar-expand-lg navbar-dark bg-secondary">
  <div class="container-fluid">
    <router-link :to="{ name: 'Home' }" class="navbar-brand" href="#">Accueil</router-link>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
   
        <li class="nav-item">
          <router-link :to="{ name: 'AlbumListe' }" class="nav-link">Liste des albums</router-link>
        </li>

      </ul>

      <SearchBar />

    </div>
  </div>
</nav>
 
  <router-view/>
</template>

<style>
  @import "https://cdn.jsdelivr.net/npm/bootstrap-dark-5@1.1.3/dist/css/bootstrap-dark.min.css";
.router-link-active {
  color: black;
  font-weight:bold;
}
.overflow-x-auto {
  overflow-x: auto!important;
}
.overflow-y-hidden {
    overflow-y: hidden!important;
}

</style>

<script>




import SearchBar from "./components/Search.vue";

export default {
  components: {
    SearchBar
  }
}
</script>
